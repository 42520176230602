'use client';

import { ErrorPage } from './_global/_components/Error/ErrorPage';
import * as S from './(_pages)/(withGnb)/_components/style.css';
import { Gnb } from './(_pages)/(withGnb)/_components/Gnb/Gnb';
export default function NotFound() {
  return (
    <div>
      <Gnb />
      <div className={S.NotFoundWrapper}>
        <ErrorPage
          title="페이지를 찾을 수 없습니다."
          reason={`페이지가 사라졌거나 다른 링크로 변경되었습니다.\n도움을 요청해 주시면 올바른 링크를 전달드릴게요.`}
          button={{
            text: '도움 요청하기',
            onClick: () => {
              window.location.href = 'https://spartacodingclub.channel.io/support-bots/41731';
            },
            colorScheme: 'secondary',
          }}
        />
      </div>
    </div>
  );
}
